import { ArrayElement } from '@when-fertility/shared/domain/common';
import { format } from 'date-fns';
import cn from 'classnames';
import { GqlPatientsListComponentPatientFragmentFragment, usePatientsListComponentGetPatientsQuery } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React from 'react';
import { Link } from 'react-router-dom';
import { testKitService } from 'domain/test-kit';
import { NURSE_ROUTES } from '../../nurse.routes';

type Props = {
  className?: string;
  search: string;
};
export const PatientsListComponent = ({ className, search }: Props) => {
  const { data, loading, error } = usePatientsListComponentGetPatientsQuery({
    variables: {
      PatientsInput: {
        search,
      },
    },
  });

  return (
    <div className={cn(className, 'w-full')}>
      <div className="hidden sm:flex gap-2 bg-silver-100 p-6 text-charcoal-100">
        <div className="w-1/5">Patient</div>
        <div className="w-1/5">Email Address</div>
        <div className="w-1/5">Mobile Number</div>
        <div className="w-1/5">Date of Birth</div>
        <div className="w-1/5"></div>
      </div>

      <div className="flex flex-col sm:bg-white justify-center text-charcoal-100 w-full">
        <>
          {error && <div className="italic">Something went wrong trying to get patients</div>}
          {!error && !loading && !data?.patients.length && <div className="italic">No patients</div>}
          {loading && <div>Loading...</div>}

          {!error &&
            !loading &&
            data?.patients.length &&
            data?.patients.map((patient) => {
              return (
                <div className="flex w-full border-t first:border-t-0 border-charcoal-20 p-6 gap-2" key={patient.id}>
                  <div className="w-1/5">
                    {patient.user.firstName} {patient.user.lastName}
                  </div>
                  <div className="w-1/5 truncate">{patient.user.email}</div>
                  <div className="w-1/5">{patient.user.phone}</div>
                  <div className="w-1/5">{format(new Date(patient.user.dateOfBirth), 'd MMM yyyy')}</div>
                  <div className="w-1/5">
                    <Link to={`${NURSE_ROUTES.patientProfile}?id=${patient.id}`} className="underline text-artichoke-100">
                      View profile
                    </Link>
                  </div>
                </div>
              );
            })}
        </>
      </div>
    </div>
  );
};

PatientsListComponent.fragments = {
  patient: gql`
    fragment PatientsListComponentPatientFragment on Patient {
      id
      user {
        firstName
        lastName
        email
        dateOfBirth
        phone
      }
    }
  `,
};

PatientsListComponent.query = gql`
  ${PatientsListComponent.fragments.patient}

  query PatientsListComponentGetPatients($PatientsInput: PatientsInput) {
    patients(input: $PatientsInput) {
      ...PatientsListComponentPatientFragment
    }
  }
`;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { App } from 'app';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { apolloService } from 'domain/common';
import { AuthProvider } from 'domain/auth';

const container = document.getElementById('app');

if (!container) {
  throw new Error('Failed to find the root element');
}
const root = ReactDOM.createRoot(container);

// TODO: add auth provider here once auth is set up
root.render(
  <BrowserRouter>
    <ApolloProvider client={apolloService}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
  </BrowserRouter>
);

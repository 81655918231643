import { GqlUser } from '@when-fertility/shared/gql/graphql';
import React, { ChangeEventHandler } from 'react';
import cn from 'classnames';
import { NoteSection } from '..';
import { TextArea } from '@when-fertility/shared/domain/common';

type Props = {
  apptNotes: any;
  handleNoteChange: ChangeEventHandler<HTMLInputElement>;
};

export const NoteActions = ({ apptNotes, handleNoteChange }: Props) => {
  return (
    <NoteSection headerText="Actions">
      <div className="md:flex flex-col p-6 border bg-clip-border rounded-xl border-silver-100 bg-silver-40 last:border-none mt-4">
        <div className="w-full mb-4 mt-2 flex flex-row-reverse">
          <select
            id="actions-onContraception"
            type="select"
            value={apptNotes?.actions?.onContraception || ''}
            className={cn('bg-white border-silver-100 border rounded h-6 w-16 mt-4 text-center')}
            onChange={handleNoteChange}
          >
            <option defaultValue></option>
            <option value="Yes">Yes</option>
            <option value="N/A">N/A</option>
          </select>
          <label htmlFor="actions-onContraception" className="mr-2 mt-4">
            If on hormonal or recent hormonal contraception discussed retesting to confirm low result
          </label>
        </div>
        <div className="flex w-full flex-row-reverse">
          <input
            id="actions-recommendGpAppt"
            type="checkbox"
            checked={apptNotes?.actions?.recommendGpAppt}
            className={cn('border-silver-100 h-6 w-6')}
            onChange={handleNoteChange}
          />
          <label htmlFor="actions-recommendGpAppt" className="mr-2">
            Recommended to make appointment with GP
          </label>
        </div>
        <div className="flex w-full mt-4 flex-row-reverse">
          <input
            id="actions-showHprDownload"
            type="checkbox"
            checked={apptNotes?.actions?.showHprDownload}
            className={cn('border-silver-100 h-6 w-6')}
            onChange={handleNoteChange}
          />
          <label htmlFor="actions-showHprDownload" className="mr-2">
            Show where to download Health Practitioners Report
          </label>
        </div>
      </div>
      <div className="mt-8" />
      <TextArea id="actions-notes" value={apptNotes?.actions?.notes || ''} onChange={handleNoteChange} />
    </NoteSection>
  );
};

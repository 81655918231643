import { Card } from '@when-fertility/shared/domain/common';
import React from 'react';

type Props = {
  headerText: string;
  children: React.ReactNode;
};

export const NoteSection = ({ headerText, children }: Props) => {
  return (
    <Card className="mt-6">
      <div>
        <p className="text-xl text-charcoal-100">{headerText}</p>
      </div>
      <div>{children}</div>
    </Card>
  );
};

import { BlobIcon, Card, Icon, Page } from '@when-fertility/shared/domain/common';
import { PatientsListComponent } from '../patients/components/patients-list.component';
import { SearchPatients } from '../patients/components/search-patients.component';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const NurseDashboardPage = () => {
  const [search, setSearch] = useState('');

  const searchHandler = (e: any) => {
    setSearch(e.target.value);
  };

  const navigate = useNavigate();
  return (
    <Page
      title="Dashboard"
      headerClassName="bg-wave-result-mobile sm:bg-wave-result-standard 2xl:bg-wave-result-wide bg-bottom bg-cover bg-no-repeat h-40 sm:h-64 text-silver-60"
    >
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/3">
          <SearchPatients search={search} searchHandler={searchHandler} />
          <Card noPadding>
            <PatientsListComponent search={search} />
          </Card>
        </div>
        <div className="w-full md:w-1/3 ml-0 mt-6 md:ml-6 md:mt-0">
          <Card>
            <div className="flex justify-between items-center">
              <a
                className="flex items-center gap-3 hover:cursor flex-1"
                href={'https://us02web.zoom.us/appointment'}
                target="_blank"
                rel="noreferrer"
              >
                <BlobIcon icon="news" variant="filled" />
                Edit my schedule
                <div className="flex-1 flex justify-end">
                  <Icon icon="arrow-right" className="text-silver-60" height={23} width={30} />
                </div>
              </a>
            </div>
            <div className="mt-4">Set up time slots that you&apos;re available to accept bookings via Zoom Scheduler</div>
          </Card>
        </div>
      </div>
    </Page>
  );
};

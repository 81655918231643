import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { User } from 'firebase/auth';
import { authService } from './auth.service';
import { clearLocalApolloCache } from '../common';
import { localStorageService, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from './auth.routes';
import { NURSE_ROUTES } from '../nurse';

export type AuthContextType = {
  firebaseUser: User | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  logout: () => Promise<void>;
  logInAndRedirect: ({ email, password, fallbackPath }: { email: string; password: string; fallbackPath?: string }) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  refreshAuth: () => Promise<void>;
  token: string | null;
};

function extractDomainFromEmail(email: string) {
  // Extract domain from email address
  return email.substring(email.lastIndexOf('@') + 1);
}

function isDomainAllowed(domain: string) {
  // Check if the domain is allowed (implement your logic here)
  const allowedDomains = ['palomagroup.com', 'whenfertility.com.au', 'phewcyber.com']; // Add your allowed domains here
  return allowedDomains.includes(domain);
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [token, setToken] = useState<AuthContextType['token']>(null);
  const [firebaseUser, setFirebaseUser] = useState<AuthContextType['firebaseUser']>(null);
  const navigate = useNavigate();

  useEffect(() => {
    authService.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser && firebaseUser.email) {
        const domain = extractDomainFromEmail(firebaseUser.email);
        // Check if the domain is allowed
        if (isDomainAllowed(domain)) {
          // Domain is allowed, proceed with authentication
          if (window.location.href.includes(AUTH_ROUTES.loginGoogle)) {
            navigate(NURSE_ROUTES.dashboard);
          }
        } else {
          // Domain is not allowed, handle the error
          console.error('Domain not allowed. Access denied.');

          navigate(`${AUTH_ROUTES.error}?${URL_PARAMETERS.errCode}=invalid-domain`);
          // You can display an error message to the user or perform other actions.
          await logout();
        }
      }
      setFirebaseUser(firebaseUser);
      if (firebaseUser) {
        setToken(await firebaseUser.getIdToken());
      } else {
        setToken(null);
      }
      setIsAuthenticating(false);
    });

    authService.onIdTokenChanged(async () => {
      // If the token refreshes, we will store the new token in state.
      if (firebaseUser) {
        setToken(await firebaseUser.getIdToken());
      }
    });
  }, []);

  useEffect(() => {
    if (token) {
      localStorageService.setItem('firebase-token', token);
    } else {
      localStorageService.removeItem('firebase-token');
    }
  }, [token]);

  const logout = async () => {
    await authService.signOut();
    clearLocalApolloCache();
  };

  const signInWithGoogle = async () => {
    await logout();
    await authService.signInWithGoogle();
  };
  const logInAndRedirect = async ({
    email,
    password,
    fallbackPath = NURSE_ROUTES.dashboard,
  }: {
    email: string;
    password: string;
    fallbackPath?: string;
  }) => {
    await signInWithGoogle();
  };

  const refreshAuth = async () => {
    const firebaseUser = await authService.reloadFirebaseUser();

    setFirebaseUser(firebaseUser);
    if (firebaseUser) {
      setToken(await firebaseUser.getIdToken());
    }
  };

  const value = useMemo(
    () => ({
      firebaseUser,
      isAuthenticated: Boolean(firebaseUser) && Boolean(token),
      isAuthenticating,
      logout,
      logInAndRedirect,
      refreshAuth,
      token,
      signInWithGoogle,
    }),
    [isAuthenticating, firebaseUser, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('This component must be used within a <AuthProvider> component.');
  }

  return context;
};

import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { localStorageService } from '@when-fertility/shared/domain/common';
import { onError } from '@apollo/client/link/error';
import { authService } from 'domain/auth';

const removeTypenameLink = removeTypenameFromVariables();

const httpLink = new HttpLink({
  uri: process.env.API_URL,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorageService.getItem('firebase-token');

  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, forward, operation }) => {
  if (graphQLErrors && graphQLErrors.find((error) => error.message.toLowerCase().includes('authentication failed'))) {
    authService.reloadFirebaseUser().then((firebaseUser) => {
      const oldHeaders = operation.getContext().headers;
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: firebaseUser?.getIdToken(),
        },
      });
      // Retry the request, returning the new observable
      return forward(operation);
    });
  }
});

export const apolloCache = new InMemoryCache();

export const apolloService = new ApolloClient({
  link: ApolloLink.from([removeTypenameLink, authLink, errorLink, httpLink]),
  cache: apolloCache,
});

export const clearLocalApolloCache = async () => {
  await apolloService.clearStore();
};

import { COMMON_ROUTES } from '@when-fertility/shared/domain/common';
import { ErrorComponent } from '@when-fertility/shared/domain/common';
import { NURSE_ROUTES } from 'domain/nurse';
import { useCurrentUser } from 'domain/user/current-user.hook';
import { GqlUserRole } from '@when-fertility/shared/gql/graphql';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';

type Props = {
  role: GqlUserRole;
};

export const RoleLayout = ({ role }: Props) => {
  const { role: currentUserRole, isLoading, error } = useCurrentUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!currentUserRole || !role || currentUserRole === role) {
      return;
    }

    // If we're a nurse and being taken to patient dashboard, re-route to nurse dashboard
    if (pathname.includes('dashboard') && currentUserRole === 'NURSE') {
      navigate(NURSE_ROUTES.dashboard);
      return;
    }

    navigate(COMMON_ROUTES.unauthorised);
  }, [pathname, currentUserRole, role]);

  // @TODO: Add better error page.
  if (error) {
    console.error(error);
    captureException(error);
    return <ErrorComponent isPage />;
  }

  if (isLoading || !currentUserRole || !role) {
    return null;
  }

  return <Outlet />;
};

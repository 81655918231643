import React, { useState } from 'react';
import { PatientsListComponent } from './components/patients-list.component';
import { Page } from '@when-fertility/shared/domain/common';
import { SearchPatients } from './components/search-patients.component';

export const PatientsListPage = () => {
  const [search, setSearch] = useState('');

  const searchHandler = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <Page title="Patients">
      <SearchPatients searchHandler={searchHandler} search={search} />
      <PatientsListComponent className="rounded-lg overflow-hidden" search={search} />
    </Page>
  );
};

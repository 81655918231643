import { useSignUpMutation } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '..';
import { Button, Input, Page } from '@when-fertility/shared/domain/common';

export const RegisterPage = () => {
  const { logInAndRedirect, logout } = useAuth();
  const [formError, setFormError] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const [signUp, { loading: isLoading }] = useSignUpMutation({
    variables: {
      input: {
        ...formData,
      },
    },
    onCompleted: async () => {
      await logInAndRedirect({ email: formData.email, password: formData.password });
    },
    onError: async (error) => {
      setFormError(error.message);
    },
  });

  useEffect(() => {
    (async () => {
      // Logging the user out of any sessions they may be in if they come directly to the login page.
      await logout();
    })();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setFormError('');

    if (formData.password.length < 6) {
      setFormError('Password must be at least 6 characters.');
      return;
    }

    await signUp();
  };

  return (
    <Page isCentred variant="medium" className="bg-blob-outline bg-center bg-no-repeat bg-[length:130%] md:bg-[length:auto]">
      <div className="w-full sm:w-80">
        <div>
          <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">Create account</h2>
        </div>
        <form className="mt-8" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm">
            <div className="flex flex-col justify-between md:flex-row gap-3 mb-3">
              <Input
                type="text"
                onChange={({ target }) => setFormData({ ...formData, firstName: target.value })}
                value={formData.firstName}
                isDisabled={isLoading}
                isRequired
                className="w-full"
                placeholder="First name"
              />
              <Input
                type="text"
                onChange={({ target }) => setFormData({ ...formData, lastName: target.value })}
                value={formData.lastName}
                isDisabled={isLoading}
                isRequired
                className="w-full"
                placeholder="Last name"
              />
            </div>
            <div className="mb-3">
              <Input
                type="email"
                onChange={({ target }) => setFormData({ ...formData, email: target.value })}
                value={formData.email}
                autoComplete="username"
                isDisabled={isLoading}
                isRequired
                placeholder="Email address"
              />
            </div>
            <div className="mb-3">
              <Input
                onChange={({ target }) => setFormData({ ...formData, password: target.value })}
                autoComplete="current-password"
                type="password"
                value={formData.password}
                isDisabled={isLoading}
                isRequired
                placeholder="Password"
              />
            </div>
            {formError && <div className="text-red-600 text-center text-sm mb-4 mt-2">{formError}</div>}
          </div>
          <Button type="submit" isDisabled={isLoading} className="w-full">
            Create Account
          </Button>
          <div className="flex flex-col items-center mt-6">
            <div className="mb-2 text-xs">
              Already a member?
              <Link to="/login" className="pl-1 underline">
                Log in
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Page>
  );
};

RegisterPage.mutation = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      email
      firstName
      lastName
    }
  }
`;

import { GqlResultsPageTestKitFragmentFragment } from '@when-fertility/shared/gql/graphql';
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Button, Icon } from '@when-fertility/shared/domain/common';
import { REFERENCE_RANGE_MAP } from '@when-fertility/shared/domain/test-kit';
import { DownloadReportButton } from '@when-fertility/shared/domain/results';
import { useNavigate } from 'react-router-dom';
import { NURSE_ROUTES } from 'domain/nurse';
import productImagePath from '@when-fertility/shared/assets/images/product.png';

type Props = {
  testKit: GqlResultsPageTestKitFragmentFragment;
};

export const ResultListItem = ({ testKit }: Props) => {
  const navigate = useNavigate();

  const emptyApptNotes = useMemo(() => {
    return Object.values(testKit?.apptNotes)
      .filter((o) => o !== 'ApptNotes')
      .every((o) => o === null);
  }, [testKit]);

  const referenceRangeName = useMemo(() => {
    return testKit.results?.map((result) => {
      const report = result.report!;
      if (!report) {
        return 'Unknown';
      }
      return REFERENCE_RANGE_MAP[report.referenceRange];
    })[0];
  }, [testKit]);

  return (
    <div
      className={`bg-silver-60 flex gap-2 p-6 sm:px-10 sm:py-4 text-charcoal-80 border sm:border-t-0 border-silver-100 rounded-lg sm:rounded-none last:rounded-b-lg my-3 sm:m-0`}
    >
      <div className="w-4/12 sm:flex">
        <div className="w-full sm:w-1/5">
          <img src={productImagePath} width={47} height={63} alt={'product'} />
        </div>
        <div className="hidden sm:block w-4/5 p-5 text-base">{testKit.product?.title}</div>
      </div>
      {/*Mobile view*/}
      <div className="flex flex-col sm:hidden">
        <div className="block sm:hidden w-4/5 p-0 sm:p-6">{testKit.product?.title}</div>
        <div className="w-full sm:w-1/4">
          <div className="pb-3 sm:py-6">
            <span className="sm:hidden">Sample collected at: </span>
            {testKit.dateSampleCollected && format(new Date(testKit.dateSampleCollected), 'dd MMMM yyyy')}
          </div>
        </div>
        <div className="border border-silver-100"></div>
        <div className="w-full sm:w-1/4 pt-3 sm:p-0">
          {testKit.status && !['CLOSED', 'CANCELLED'].includes(testKit.status) && testKit.resultsStatus === 'NOT_RECEIVED' && (
            <div className="text-charcoal-60 flex items-center">In progress</div>
          )}
          {testKit.status !== 'CANCELLED' && testKit.resultsStatus !== 'NOT_RECEIVED' && (
            <div className="flex flex-col  items-start">
              {testKit.resultsStatus === 'RECEIVED_RELEASED' && <DownloadReportButton testKitId={testKit?.id} reportType={'EGG_COUNT_REPORT'} />}
            </div>
          )}
        </div>
      </div>
      <div className="hidden sm:block w-2/12">
        <div className="py-6 text-base">{testKit.dateSampleCollected ? format(new Date(testKit.dateSampleCollected), 'dd MMMM yyyy') : '-'}</div>
      </div>
      <div className="hidden sm:block w-1/12">{testKit.results && <div className="py-6 text-base">{referenceRangeName}</div>}</div>
      <div className="hidden sm:block w-1/12">
        <div className="py-6 text-base">
          {testKit.apptNotesReportFile?.s3Path && (
            <Icon
              icon="check"
              stroke-width="2"
              height={24}
              width={24}
              className="bg-charcoal-100 text-silver-100 border border-charcoal-100 rounded p-1"
            />
          )}
        </div>
      </div>
      <div className="hidden sm:block w-2/12">
        <div className="py-6 text-base">
          {testKit.apptNotesReportFile?.s3Path && <DownloadReportButton size="regular" testKitId={testKit?.id} reportType={'APPOINTMENT_NOTE'} />}
          {!testKit.apptNotesReportFile?.s3Path && referenceRangeName === 'Low' && (
            <Button
              isLink
              className="text-xs"
              onClick={() => {
                navigate(`${NURSE_ROUTES.apptNote.replace(':id', testKit.id)}?id=${testKit.userId}&testKitId=${testKit.id}`);
              }}
            >
              {emptyApptNotes ? 'Create' : 'Edit'} Notes
            </Button>
          )}
        </div>
      </div>
      <div className="hidden sm:flex w-2/12 items-center text-base justify-center">
        {testKit.status && !['CLOSED', 'CANCELLED'].includes(testKit.status) && testKit.resultsStatus === 'NOT_RECEIVED' && (
          <div className="text-charcoal-60 flex items-center">
            In progress <Icon icon="info" className="ml-1" />
          </div>
        )}
        {testKit.status !== 'CANCELLED' && testKit.resultsStatus !== 'NOT_RECEIVED' && (
          <div className="flex flex-col items-center">
            {(testKit.resultsStatus === 'RECEIVED_RELEASED' || testKit.resultsStatus === 'RECEIVED_NOT_RELEASED') && (
              <>
                <DownloadReportButton testKitId={testKit.id} reportType={'EGG_COUNT_REPORT'} />
                <div className="mt-4" />
                <DownloadReportButton testKitId={testKit.id} reportType={'HEALTH_PRACTITIONER_REPORT'} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

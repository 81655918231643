import { Card, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GqlUser } from '@when-fertility/shared/gql/graphql';
import { PatientInformationDetail } from './patient-information-detail.component';

export const PatientInformation = ({ user }: { user: Partial<GqlUser> | undefined }) => {
  const [searchParams] = useSearchParams();
  const userId = useMemo(() => searchParams.get(URL_PARAMETERS.userId), [searchParams]);

  if (!userId) {
    // TODO: do sumfink
    return null;
  }
  return (
    <Card>
      <div className="text-2xl">Patient Information</div>
      <PatientInformationDetail user={user} />
    </Card>
  );
};

import { GqlUser } from '@when-fertility/shared/gql/graphql';
import React, { ChangeEventHandler } from 'react';
import cn from 'classnames';
import { PatientInformationDetail } from './patient-information-detail.component';
import { NoteSection } from '..';
import { TextArea } from '@when-fertility/shared/domain/common';

type Props = {
  user: Partial<GqlUser> | undefined;
  apptNotes: any;
  handleNoteChange: ChangeEventHandler<HTMLInputElement>;
};

export const NoteAgenda = ({ user, apptNotes, handleNoteChange }: Props) => {
  return (
    <NoteSection headerText="Agenda">
      <PatientInformationDetail user={user}>
        <div className="md:flex flex-col p-6 border-b bg-clip-border rounded-b-xl border-silver-100 bg-silver-40 last:border-none">
          <div className="flex w-full mb-4 flex-row-reverse">
            <input
              id="agenda-introduced"
              type="checkbox"
              className={cn('border-silver-100 h-6 w-6 mt-2')}
              onChange={handleNoteChange}
              checked={apptNotes?.agenda?.introduced || false}
            />
            <label htmlFor="agenda-introduced" className="mr-2 mt-2">
              Introduced yourself using: [name] + Fertility Nurse + at WHEN + appointment purpose
            </label>
          </div>
          <div className="flex w-full mb-4 mt-2 flex-row-reverse">
            <input
              id="agenda-verify"
              type="checkbox"
              className={cn('border-silver-100 h-6 w-6')}
              onChange={handleNoteChange}
              checked={apptNotes?.agenda?.verify || false}
            />
            <label htmlFor="agenda-verify" className="mr-2">
              Has the client’s ID above been verified? (note the details of any other persons present)
            </label>
          </div>
          <div className="flex w-full mb-4 mt-2 flex-row-reverse">
            <input
              id="agenda-purpose"
              type="checkbox"
              className={cn('border-silver-100 h-6 w-6')}
              onChange={handleNoteChange}
              checked={apptNotes?.agenda?.purpose || false}
            />
            <label htmlFor="agenda-purpose" className="mr-2">
              Purpose of appointment explained
            </label>
          </div>
          <div className="flex w-full mb-4 mt-2 flex-row-reverse">
            <input
              id="agenda-consent"
              type="checkbox"
              className={cn('border-silver-100 h-6 w-6')}
              onChange={handleNoteChange}
              checked={apptNotes?.agenda?.consent || false}
            />
            <label htmlFor="agenda-consent" className="mr-2">
              Informed consent to proceed granted
            </label>
          </div>
          <div className="flex w-full mb-4 mt-2 flex-row-reverse">
            <input
              id="agenda-confirmedTeleconferencing"
              type="checkbox"
              className={cn('border-silver-100 h-6 w-6')}
              onChange={handleNoteChange}
              checked={apptNotes?.agenda?.confirmedTeleconferencing || false}
            />
            <label htmlFor="agenda-confirmedTeleconferencing" className="mr-2">
              Confirmed they are comfortable using teleconferencing
            </label>
          </div>
          <div className="flex w-full mb-4 mt-2 flex-row-reverse">
            <input
              id="agenda-communicatedNoMedicalHistory"
              type="checkbox"
              className={cn('border-silver-100 h-6 w-6')}
              onChange={handleNoteChange}
              checked={apptNotes?.agenda?.communicatedNoMedicalHistory || false}
            />
            <label htmlFor="agenda-communicatedNoMedicalHistory" className="mr-2">
              Communicated no medical history or ART discussion
            </label>
          </div>
        </div>
      </PatientInformationDetail>
      <div className="mt-8" />
      <TextArea id="agenda-notes" value={apptNotes?.agenda?.notes || ''} onChange={handleNoteChange} />
    </NoteSection>
  );
};

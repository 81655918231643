import { GqlUser } from '@when-fertility/shared/gql/graphql';
import React, { ChangeEventHandler } from 'react';
import cn from 'classnames';
import { NoteSection } from '..';
import { useUserProfile } from 'domain/user';
import { TextArea } from '@when-fertility/shared/domain/common';
import { differenceInYears } from 'date-fns';

type Props = {
  user: Partial<GqlUser> | undefined;
  apptNotes: any;
  handleNoteChange: ChangeEventHandler<HTMLInputElement>;
};

export const NoteAudit = ({ user, apptNotes, handleNoteChange }: Props) => {
  const { profileQuestions, prettyPrintAnswer } = useUserProfile();

  const calculateAge = function (birthDate: string | Date | undefined) {
    if (!birthDate) {
      return 0;
    }

    birthDate = new Date(birthDate);
    const currentDate = new Date();

    return differenceInYears(currentDate, birthDate);
  };

  return (
    <NoteSection headerText="Audit">
      <div className="rounded-xl border border-silver-100 mt-8">
        <React.Fragment>
          <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
            <div className="text-charcoal-60">Age</div>
            <div className="flex justify-between items-center md:ml-12">{calculateAge(user?.dateOfBirth)}</div>
          </div>
          <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
            <div className="text-charcoal-60">Relationship Status</div>
            <div className="flex justify-between items-center md:ml-12">
              {prettyPrintAnswer(profileQuestions.find((i) => i.key === 'TEST_RELATIONSHIP_STATUS'))}
            </div>
          </div>
          <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
            <div className="text-charcoal-60">Family planning goal</div>
            <div className="flex justify-between items-center md:ml-12">
              {prettyPrintAnswer(profileQuestions.find((i) => i.key === 'TEST_PLANING'))}
            </div>
          </div>
          <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
            <div className="text-charcoal-60">Current Contraception use</div>
            <div className="flex justify-between items-center md:ml-12">
              {prettyPrintAnswer(profileQuestions.find((i) => i.key === 'CONTRACEPTION_TYPE'))}
            </div>
          </div>
          <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
            <div className="text-charcoal-60">Recent Contraception use</div>
            <div className="flex justify-between items-center md:ml-12">
              {prettyPrintAnswer(profileQuestions.find((i) => i.key === 'RECENT_HORMONAL_CONTRACEPTION'))}
            </div>
          </div>
          <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
            <div className="text-charcoal-60">History & additional notes</div>
            <div className="flex justify-between items-center md:ml-12">{prettyPrintAnswer(profileQuestions[11])}</div>
          </div>
          <div className="md:flex p-6 border-b bg-clip-border rounded-b-xl border-silver-100 bg-silver-40 last:border-none">
            <div className="flex-col">
              <div className="w-full mb-4 mt-2 flex flex-row-reverse">
                <select
                  id="audit-informationCorrect"
                  value={apptNotes?.audit?.informationCorrect || ''}
                  className={cn('bg-white border-silver-100 border rounded h-6 w-16 mt-4 text-center')}
                  onChange={handleNoteChange}
                >
                  <option value=""></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <label htmlFor="audit-informationCorrect" className="mr-2 mt-4 text-right">
                  Is the above questionnaire information correct?
                </label>
              </div>
              <div className="w-full mb-4 mt-2 flex flex-row-reverse">
                <select
                  id="audit-recentContraception"
                  value={apptNotes?.audit?.recentContraception || ''}
                  className={cn('bg-white border-silver-100 border rounded h-6 w-16 mt-4 text-center')}
                  onChange={handleNoteChange}
                >
                  <option value=""></option>
                  <option value="Yes">Yes</option>
                  <option value="N/A">N/A</option>
                </select>
                <label htmlFor="audit-recentContraception" className="mr-2 mt-4 text-right">
                  If client has current or recent contraception use, have you told them about the potential lowering of their result?
                </label>
              </div>
              <div className="w-full mb-4 mt-2 flex flex-row-reverse">
                <select
                  id="audit-lowerByMedicalHistory"
                  value={apptNotes?.audit?.lowerByMedicalHistory || ''}
                  className={cn('bg-white border-silver-100 border rounded h-6 w-16 mt-4 text-center')}
                  onChange={handleNoteChange}
                >
                  <option value=""></option>
                  <option value="Yes">Yes</option>
                  <option value="N/A">N/A</option>
                </select>
                <label htmlFor="audit-lowerByMedicalHistory" className="mr-2 mt-4 text-right">
                  Was the client informed of any medical history that could have contributed to their low result? If yes, detail below
                </label>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
      <div className="mt-8" />
      <TextArea id="audit-notes" value={apptNotes?.audit?.notes || ''} onChange={handleNoteChange} />
    </NoteSection>
  );
};

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthenticatedLayout, RoleLayout, UnauthenticatedLayout } from 'domain/app';
import { AUTH_ROUTES, LogoutPage, ErrorMessagePage, LoginGooglePage } from 'domain/auth';

import { PatientsListPage, NURSE_ROUTES, PatientProfilePage, NurseDashboardPage, ApptNotePage, PatientAuditHistoryPage } from 'domain/nurse';
import { COMMON_ROUTES, UnauthorisedPage } from '@when-fertility/shared/domain/common';

export const App = () => {
  return (
    <main className="bg-silver-40 min-h-screen relative font-abc-diatype text-charcoal-100">
      <Routes>
        <Route element={<UnauthenticatedLayout />}>
          <Route path={AUTH_ROUTES.logout} element={<LogoutPage />} />
          {/*<Route path={AUTH_ROUTES.login} element={<LoginPage />} />*/}
          <Route path={AUTH_ROUTES.loginGoogle} element={<LoginGooglePage />} />
          <Route path={AUTH_ROUTES.error} element={<ErrorMessagePage />} />
          {/*<Route path={AUTH_ROUTES.forgotPassword} element={<ForgotPasswordPage />} />*/}
          {/*<Route path={AUTH_ROUTES.register} element={<RegisterPage />} />*/}
        </Route>
        <Route element={<AuthenticatedLayout />}>
          <Route element={<RoleLayout role="NURSE" />}>
            <Route path={NURSE_ROUTES.dashboard} element={<NurseDashboardPage />} />
            <Route path={NURSE_ROUTES.patients} element={<PatientsListPage />} />
            <Route path={NURSE_ROUTES.patientProfile} element={<PatientProfilePage />} />
            <Route path={NURSE_ROUTES.patientAuditHistory} element={<PatientAuditHistoryPage />} />
            <Route path={NURSE_ROUTES.apptNote} element={<ApptNotePage />} />
            <Route path={COMMON_ROUTES.unauthorised} element={<UnauthorisedPage />} />
          </Route>
          <Route path="/" element={<Navigate to={NURSE_ROUTES.dashboard} />} />
        </Route>
      </Routes>
    </main>
  );
};

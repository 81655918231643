import React, { useMemo } from 'react';
import { Page, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { useSearchParams } from 'react-router-dom';
export const ErrorMessagePage = () => {
  const [searchParams] = useSearchParams();
  const errorCode = useMemo(() => searchParams.get(URL_PARAMETERS.errCode), [searchParams]);
  const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'invalid-domain':
        return 'Domain not allowed. Please use WHEN domain to try again.';
    }
  };
  return (
    <Page isCentred variant="medium" className="bg-blob-outline bg-center bg-no-repeat bg-[length:130%] md:bg-[length:auto]">
      <div className="max-w-md p-8 rounded-lg">
        <h2 className="text-3xl font-bold mb-4">Oops! Something went wrong.</h2>
        <p className="text-gray-700 mb-4">{errorCode ? `Error: ${getErrorMessage(errorCode)}` : "We're sorry, but an unexpected error occurred."}</p>
      </div>
    </Page>
  );
};

import React from 'react';
import { useAuth } from 'domain/auth';
import { Icon, HeaderNavLink } from '@when-fertility/shared/domain/common';
import { Link } from 'react-router-dom';
import { NURSE_ROUTES } from '../../../nurse';

export const UnauthenticatedNav = () => {
  const { signInWithGoogle } = useAuth();

  return (
    <div className="absolute sticky top-0 z-50 bg-silver-100 bg-transparent border-b border-b-charcoal-60">
      <div className="flex justify-between w-full items-center px-4 h-nav-mobile md:h-nav-desktop">
        <div className="flex items-center">
          <Link to={NURSE_ROUTES.dashboard}>
            <div className="hidden md:block">
              <Icon icon="when" height={30} width={100} />
            </div>
            <div className="block md:hidden">
              <Icon icon="when" height={30} width={100} />
            </div>
          </Link>
        </div>
        <div>
          <div className="flex h-8 text-xs">
            <HeaderNavLink onClick={() => signInWithGoogle()} className="flex items-center bg-white rounded-full px-4">
              Log in
            </HeaderNavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useAuth, AUTH_ROUTES } from 'domain/auth';
import { ErrorComponent, localStorageService } from '@when-fertility/shared/domain/common';
import { NurseNav } from 'domain/nurse';
import { useCurrentUser } from 'domain/user';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { captureException } from '@sentry/react';

export const AuthenticatedLayout = () => {
  const { isAuthenticating, isAuthenticated, firebaseUser } = useAuth();
  const { role } = useCurrentUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticating) {
          return;
        }
        if (!isAuthenticated || !firebaseUser) {
          const currentPath = [pathname, searchParams.toString()].join('?');
          localStorageService.setItem('redirect', currentPath);
          navigate(AUTH_ROUTES.loginGoogle);
        }

        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        captureException(error);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, [isAuthenticating, isAuthenticated]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <ErrorComponent isPage />;
  }

  return (
    <>
      {role === 'NURSE' && <NurseNav />}
      <Outlet />
    </>
  );
};

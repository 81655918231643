import React from 'react';
import { useAuth } from '../auth.provider';
import { Button, Page } from '@when-fertility/shared/domain/common';

export const LoginGooglePage = () => {
  const { signInWithGoogle, isAuthenticating } = useAuth();
  return (
    <Page isCentred variant="medium" className="bg-blob-outline bg-center bg-no-repeat bg-[length:130%] md:bg-[length:auto]">
      <div className="w-full sm:w-80 px-6">
        {isAuthenticating ? (
          <h2 className="mt-4 text-3xl text-center">Loading...</h2>
        ) : (
          <>
            <h2 className="mt-4 text-3xl text-center">Log in With Google</h2>
            <Button onClick={() => signInWithGoogle()} className="w-full mb-8 mt-8">
              Log in with google
            </Button>
          </>
        )}
      </div>
    </Page>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { ProfileQuestion } from '@when-fertility/shared/user-profile';
import cn from 'classnames';
import { useUserProfile } from '..';

type Props = {
  options: ProfileQuestion['options'];
  key: string;
};

export const QuizSingleChoice = ({ options, key }: Props) => {
  const { currentQuestion, formData, updateField } = useUserProfile();
  const labels = Object.values(options || {});
  const values = Object.keys(options || {});

  const currentQuestionData = useMemo(() => formData[currentQuestion.key], [formData, currentQuestion]);
  const [comment, setComment] = useState<string>(currentQuestionData?.comment || '');
  const [selectedValue, setSelectedValue] = useState<string | null>();

  const handleClick = ({ value }: { value: string }) => {
    if (value !== 'other') {
      setComment('');
    }
    setSelectedValue(value);
    updateField({ id: currentQuestion.key, value: { value: value, comment: comment } });
  };

  useEffect(() => {
    updateField({ id: currentQuestion.key, value: { value: selectedValue, comment: comment } });
  }, [comment, currentQuestion]);

  return (
    <div key={key}>
      {labels.map((option, count) => {
        return (
          <div
            className={cn(
              'min-h-quiz-multi-select flex items-center bg-silver-80 hover:bg-silver-100 mt-2 rounded-full px-4 py-2 hover:cursor-pointer border-3 leading-5',
              {
                'border border-charcoal-100': currentQuestionData?.value === values[count],
              }
            )}
            key={option}
            onClick={() => handleClick({ value: values[count] })}
          >
            {option}
            {currentQuestion.showCommentIfSelected === selectedValue && values[count] === selectedValue ? (
              <div className={'flex flex-1'}>
                <input
                  type="text"
                  className="rounded-full p-2 pl-4 ml-2 relative flex-row w-full"
                  value={comment}
                  onChange={({ target }) => {
                    setComment(target.value);
                  }}
                  placeholder={'Write notes here...'}
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

import React, { useMemo, useState } from 'react';
import { Quiz } from './components/quiz.component';
import { PatientProfileProvider } from '../user/patient-profile.provider';
import { Button, Modal, Page } from '@when-fertility/shared/domain/common';
import { QuizHeader } from './components/quiz-header.component';
import { QUIZ_ROUTES } from './quiz.routes';
import { useNavigate } from 'react-router-dom';
import gql from 'graphql-tag';
import { testKitService } from '../test-kit';
import { GqlQuizPageTestKitFragmentFragment, useQuizPageGetTestKitsQuery } from '@when-fertility/shared/gql/graphql';
import { NURSE_ROUTES } from '../nurse';

export const QuizPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { data } = useQuizPageGetTestKitsQuery({ fetchPolicy: 'cache-and-network' });

  const latestTestKit = useMemo(() => testKitService.getLatestTestKit<GqlQuizPageTestKitFragmentFragment>(data?.testKits.testKits), [data]);
  const isTestKitActivated = useMemo(() => testKitService.getIsTestKitActivated(latestTestKit?.status), [latestTestKit?.status]);

  // TODO: any dynamic loading of which kit we are loading, or handling the quiz for multiple kits?
  return (
    <>
      <Page isCentred variant="bright">
        <div className="w-full md:w-96 p-6">
          <div className="text-center">
            {isTestKitActivated && (
              <>
                <div className="text-2xl">Your kit has been activated!</div>
                <div className="text-md font-bold">Now, let&apos;s complete your profile.</div>
              </>
            )}
            {!isTestKitActivated && (
              <>
                <div className="text-2xl">Complete your profile</div>
              </>
            )}
            <div className="mt-6">
              In order to personalise your results & deliver valuable insights into your ovarian reserve, we need to know a bit more about you.
            </div>
            <div className="mt-4">We&apos;re going to ask questions about your health to best inform our medical team of your journey so far.</div>
          </div>
          <div className="absolute bottom-0 left-0 md:left-auto flex flex-col p-6 w-full md:w-96">
            <div className="text-center mb-2 text-charcoal-60">Takes 10 minutes</div>
            <Button onClick={() => setIsModalOpen(true)}>Let&apos;s Go</Button>
            <Button variant="secondary" className="mt-2" onClick={() => navigate(NURSE_ROUTES.dashboard)}>
              I&apos;ll do this later
            </Button>
          </div>
        </div>
        <PatientProfileProvider
          saveOnFinish
          onFinish={() => {
            navigate(QUIZ_ROUTES.complete);
          }}
        >
          <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} customHeader={<QuizHeader setIsModalOpen={setIsModalOpen} />}>
            <Quiz />
          </Modal>
        </PatientProfileProvider>
      </Page>
    </>
  );
};

QuizPage.fragments = {
  testKits: gql`
    fragment QuizPageTestKitFragment on TestKit {
      id
      status
    }
  `,
};

QuizPage.query = gql`
  ${QuizPage.fragments.testKits}

  query QuizPageGetTestKits($input: TestKitInput) {
    testKits(input: $input) {
      testKits {
        ...QuizPageTestKitFragment
      }
    }
  }
`;

import { GqlTestKit, GqlTestKitStatus } from '@when-fertility/shared/gql/graphql';

class TestKitService {
  getIsTestKitActivated = (testKitStatus: GqlTestKitStatus | null | undefined) =>
    Boolean(testKitStatus && ['SUBMITTED', 'OPEN', 'SPECIMEN_RECEIVED', 'ACTIVATED', 'CLOSED'].includes(testKitStatus));

  getLatestTestKit = <T>(testKits: (Pick<GqlTestKit, 'id' | 'status'> & T)[] | undefined) => {
    return testKits?.length && this.getIsTestKitActivated(testKits[0]?.status) ? testKits[0] : null;
  };

  getTestKitById = <T>(testKits: (Pick<GqlTestKit, 'id' | 'status'> & T)[] | undefined, testKitId: string) => {
    return testKits?.find((testKit) => testKit.id === testKitId);
  };
}

export const testKitService = new TestKitService();

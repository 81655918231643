import { Card } from '@when-fertility/shared/domain/common';
import React from 'react';
import { useGetUserAuditLogsQuery } from '@when-fertility/shared/gql/graphql';
import { format } from 'date-fns-tz';

type Props = {
  entityName: string;
  userId: string;
  header: string;
  showOldValue?: boolean;
};

const formatChangeSetValue = (val: any) => {
  if (typeof val !== 'object' || val === null) return val;
  // iterate over keys and turn them into a string of key value separated by comma
  return Object.keys(val)
    .reduce((acc: string[], key) => {
      acc.push(`${key}: ${val[key]}`);
      return acc;
    }, [])
    .join(', ');
};
function isValidDate(dateString: string) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}
function isValidDateFormat(dateString: string) {
  return dateString === format(new Date(dateString), 'yyyy-MM-dd hh:mm a');
}
function parseUTCDateString(dateString: string) {
  const [datePart, timePart, ampm] = dateString.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = timePart.split(':').map(Number);
  // Adjust hours for AM/PM
  if (ampm === 'PM' && hours < 12) hours += 12;
  if (ampm === 'AM' && hours === 12) hours = 0;
  // Create the UTC date object
  return new Date(Date.UTC(year, month - 1, day, hours, minutes));
}

const formatChange = (change: any, index: number, showOldValue: boolean) => {
  const formattedOldValue = formatChangeSetValue(change.oldValue);
  let formattedNewValue = formatChangeSetValue(change.newValue);
  if (!formattedOldValue && !formattedNewValue) {
    return null;
  }

  if (isValidDate(formattedNewValue) && isValidDateFormat(formattedNewValue)) {
    const date = parseUTCDateString(formattedNewValue);
    formattedNewValue = format(date, ' yyyy/MM/dd hh:mm a');
  }

  return <p key={index}>{`${change.propertyName}: ${showOldValue ? formattedOldValue + ' ->' : ''} ${formattedNewValue} `}</p>;
};

export const PatientAuditHistoryItem = ({ entityName, userId, header, showOldValue = true }: Props) => {
  const { data } = useGetUserAuditLogsQuery({
    variables: { input: { entityName, userId: userId } },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Card className="mb-6">
      <div className="text-xl">{header}</div>
      <table className="flex flex-col sm:bg-white justify-center text-charcoal-100 w-full">
        {data?.auditLogs?.auditLogs && data?.auditLogs?.auditLogs.length > 0 && (
          <thead>
            <tr className="flex w-full border-t first:border-t-0 border-charcoal-20 p-6 gap-2">
              <th className="w-1/5 text-left">Changed By</th>
              <th className="w-1/5 text-left">Changed Date</th>
              <th className="w-3/5 text-left">Change</th>
            </tr>
          </thead>
        )}
        <tbody>
          {!data?.auditLogs?.auditLogs.length > 0 && (
            <tr className="flex w-full">
              <td className="flex-1 text-center">No history found</td>
            </tr>
          )}
          {data?.auditLogs?.auditLogs.map((auditLog) => (
            <tr className="flex w-full border-t first:border-t-0 border-charcoal-20 p-6 gap-2" key={auditLog.createdAt}>
              <td className="w-1/5 text-left">
                <p className="truncate">{auditLog.changedBy}</p>
              </td>
              <td className="w-1/5 text-left">
                <p>{format(new Date(auditLog.createdAt), ' yyyy/MM/dd hh:mm a')}</p>
              </td>
              <td className="w-3/5 text-left">
                {auditLog.changeSet.map((change: any, index: number) => {
                  return formatChange(change, index, showOldValue);
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

import React from 'react';
import { Icon } from '@when-fertility/shared/domain/common';
import { Link } from 'react-router-dom';
import { useUserProfile } from '..';
import { NURSE_ROUTES } from '../../nurse';

type Props = {
  setIsModalOpen: (value: boolean) => void;
};

export const QuizHeader = ({ setIsModalOpen }: Props) => {
  const { next, prev, isFirstQuestion, isLastQuestion, quizProgressPercentage } = useUserProfile();

  return (
    <>
      <div className="bg-silver-60 px-3 py-2 flex justify-between items-center">
        <div>
          <Link to={NURSE_ROUTES.dashboard} onClick={() => setIsModalOpen(false)}>
            <Icon icon="when" />
          </Link>
        </div>
        <div className="flex">
          {!isFirstQuestion && (
            <div className="bg-white rounded-full mr-1 p-2 h-8 w-8 flex items-center justify-center" onClick={prev}>
              <Icon icon="arrow-left" height={9} width={12} className="stroke-[3px]" />
            </div>
          )}
          {!isLastQuestion && (
            <div className="bg-white rounded-full p-2 h-8 w-8 flex items-center justify-center" onClick={next}>
              <Icon icon="arrow-right" height={9} width={12} className="stroke-[3px]" />
            </div>
          )}
        </div>
      </div>
      <div className="bg-artichoke-60 w-full h-1">
        {/* tailwindcss 'w-[value%]' doesn't work with dynamic values so need to directly update style prop here */}
        <div style={{ width: `${quizProgressPercentage}%` }} className="bg-artichoke-100 h-1"></div>
      </div>
    </>
  );
};

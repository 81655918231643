import React from 'react';
import { Button } from '@when-fertility/shared/domain/common';
import { useUserProfile } from '../../user';
import { QuizQuestion } from './quiz-question.component';

export const Quiz = () => {
  const { next, currentQuestion } = useUserProfile();

  return (
    <div className="flex items-start justify-center">
      <div className="w-full h-full md:w-96">
        <QuizQuestion />
        <div className="absolute md:static left-0 p-6 bottom-0 md:left-auto md:p-0 md:bottom-auto flex flex-col w-full mt-6">
          {(currentQuestion.type === 'text' || currentQuestion.type === 'info' || currentQuestion.type === 'textarea') && (
            <Button onClick={next} className="mt-2">
              Next
            </Button>
          )}
          <Button onClick={next} variant="secondary" className="w-full mt-2">
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
};

// TODO: think about moving this file out of this domain

import {
  GqlPatientDetailsInput,
  GqlPatientDetailsFieldKey,
  useUpdateUserProfileMutation,
  usePatientProfileProviderGetPatientByIdQuery,
} from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { ProfileQuestion, ProfileQuestionFieldKey, profileQuestionsService } from '@when-fertility/shared/user-profile';
import { useCurrentUser } from './current-user.hook';

type Props = { userId?: string; children: ReactNode; allowNextOnChange?: boolean; onFinish?: () => void; saveOnFinish?: boolean };
type FormDataValue = { value: string; values?: string[]; comment: string };

export type UserProfileContextType = {
  formData: Partial<Record<ProfileQuestionFieldKey, FormDataValue>>;
  profileQuestions: ProfileQuestion[];
  updateField: ({ id, value }: { id: string; value: FormDataValue }) => void;
  updateFieldAndGoNext: ({ id, value }: { id: string; value: FormDataValue }) => void;
  currentQuestion: ProfileQuestion;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  allowNextOnChange: boolean;
  setCurrentQuestion: (value: ProfileQuestion) => void;
  prettyPrintAnswer: (value: ProfileQuestion) => string | null;
  resetFormData: () => void;
  canShowQuestion: ({ _formData, question }: { _formData?: Partial<Record<ProfileQuestionFieldKey, string>>; question: ProfileQuestion }) => boolean;
  next: () => void;
  prev: () => void;
  save: () => void;
  quizProgressPercentage: number;
  errorMessage?: string;
};

export const PatientProfileContext = createContext<UserProfileContextType | null>(null);

const profileQuestions = profileQuestionsService.getProfileQuestions('OVARIAN_RESERVE');

export const PatientProfileProvider = ({ userId, children, allowNextOnChange = true, onFinish, saveOnFinish }: Props) => {
  const [formData, setFormData] = useState<Partial<Record<ProfileQuestionFieldKey, string>>>({});
  const [currentQuestion, setCurrentQuestion] = useState<ProfileQuestion>(profileQuestions[0]);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const isFirstQuestion = useMemo(() => currentQuestionId - 1 < 0, [currentQuestionId, profileQuestions]);
  const isLastQuestion = useMemo(() => currentQuestionId + 1 === profileQuestions?.length, [currentQuestionId, profileQuestions]);
  const [saveProfile, { error: isErrorUpdatingProfile }] = useUpdateUserProfileMutation();
  const { loggedInUserId } = useCurrentUser();

  const canShowQuestion = ({ _formData, question }: { _formData?: Partial<Record<ProfileQuestionFieldKey, string>>; question: ProfileQuestion }) => {
    if (!question?.showIfSelected) {
      return true;
    }

    const formDataTemp = _formData || formData;

    const keys = Object.keys(question.showIfSelected);

    return Boolean(
      keys.find((key) => {
        const showIfSelectedValues = question.showIfSelected ? question.showIfSelected[key] : [];
        return showIfSelectedValues?.includes(formDataTemp[key as ProfileQuestionFieldKey]?.value || '');
      })?.length
    );
  };

  /**
   * TODO: this function currently only does checks starting from a given index in the profileQuestions array
   * It will find the first question it can display and stop iterating after that.
   * In the future we might want a version of this where we can parse the entire formData instead.
   */
  const processFormDataUpdate = ({
    questionIdToStartFrom = currentQuestionId,
    _formData,
    nextOrPrev = 'next',
  }: {
    questionIdToStartFrom?: number;
    _formData: Partial<Record<ProfileQuestionFieldKey, string>>;
    nextOrPrev?: 'next' | 'prev';
  }): { id: number; formData: Partial<Record<ProfileQuestionFieldKey, string>> } => {
    const currentQuestionIdTemp = nextOrPrev === 'next' ? questionIdToStartFrom + 1 : questionIdToStartFrom - 1;
    let formDataTemp = _formData;
    const nextQuestionTemp = profileQuestions[currentQuestionIdTemp];

    if (isLastQuestion) {
      return { id: -1, formData: formDataTemp };
    }

    if (canShowQuestion({ _formData, question: nextQuestionTemp })) {
      return { id: currentQuestionIdTemp, formData: formDataTemp };
    }
    // Can't show this question; clear the formData for this question
    formDataTemp = { ...formDataTemp, [nextQuestionTemp.key]: '' };

    const isNextQuestionLastQuestion = currentQuestionIdTemp + 1 === profileQuestions?.length;
    if (isNextQuestionLastQuestion) {
      return { id: -1, formData: formDataTemp };
    }
    // Keep looking
    return processFormDataUpdate({ questionIdToStartFrom: currentQuestionIdTemp, _formData: formDataTemp, nextOrPrev });
  };

  const updateField = ({ id, value }: { id: string; value: string }) => {
    // If it's the last question, no need to process following questions as there aren't any

    if (isLastQuestion) {
      setFormData({ ...formData, [id]: value });
      return;
    }

    const { formData: updatedFormData } = processFormDataUpdate({
      questionIdToStartFrom: profileQuestions.findIndex((q) => q.key === currentQuestion.key),
      _formData: { ...formData, [id]: value },
    });
    setFormData(updatedFormData);
  };

  const { data: patientProfileData } = usePatientProfileProviderGetPatientByIdQuery({
    ...(loggedInUserId && { variables: { input: { id: userId || loggedInUserId } } }),
    onCompleted: (data) => {
      if (!data.patientById.details) {
        return;
      }
      const profileData: Partial<Record<GqlPatientDetailsFieldKey, string>> = {};

      data.patientById.details.forEach((profileField) => {
        profileData[profileField.key] = profileField;
      });
      setFormData(profileData);
    },
    skip: !loggedInUserId,
    fetchPolicy: 'cache-and-network',
  });

  const handleFinish = async (updatedFormData: Partial<Record<ProfileQuestionFieldKey, string>>) => {
    if (saveOnFinish) {
      await save(updatedFormData, () => {
        if (onFinish) {
          onFinish();
        }
      });
      return;
    }

    if (onFinish) {
      onFinish();
    }
  };

  const next = async () => {
    if (isLastQuestion) {
      handleFinish(formData);
      return;
    }

    const { id: nextQuestionId } = processFormDataUpdate({
      questionIdToStartFrom: currentQuestionId,
      _formData: formData,
      nextOrPrev: 'next',
    });

    if (nextQuestionId !== -1) {
      setCurrentQuestionId(nextQuestionId);
      setCurrentQuestion(profileQuestions[nextQuestionId]);
      return;
    }
    handleFinish(formData);
  };

  const prev = () => {
    if (isFirstQuestion) {
      return;
    }

    const { id: nextQuestionId } = processFormDataUpdate({
      questionIdToStartFrom: currentQuestionId,
      _formData: formData,
      nextOrPrev: 'prev',
    });
    if (nextQuestionId !== -1) {
      setCurrentQuestionId(nextQuestionId);
      setCurrentQuestion(profileQuestions[nextQuestionId]);
    }
  };

  const updateFieldAndGoNext = async ({ id, value }: { id: string; value: string }) => {
    const newFormData = { ...formData, [id]: value };
    const { formData: updatedFormData, id: nextQuestionId } = processFormDataUpdate({
      questionIdToStartFrom: currentQuestionId,
      _formData: newFormData,
      nextOrPrev: 'next',
    });
    setFormData({ ...formData, ...updatedFormData });

    if (isLastQuestion) {
      handleFinish(updatedFormData);
    }

    if (nextQuestionId !== -1) {
      setCurrentQuestionId(nextQuestionId);
      setCurrentQuestion(profileQuestions[nextQuestionId]);
    } else {
      handleFinish(updatedFormData);
    }
  };

  const save = async (updatedFormData: Partial<Record<ProfileQuestionFieldKey, string>> = formData, onSuccess?: () => void) => {
    const keys = Object.keys(updatedFormData);
    const values = Object.values(updatedFormData);

    const parsedProfile: GqlPatientDetailsInput[] = [];

    keys.forEach((key, count) => {
      if (!key || !value) {
        return;
      }
      if (Array.isArray(values[count].values)) {
        parsedProfile.push({ key: key as GqlPatientDetailsFieldKey, comment: values[count].comment, values: values[count].values });
      } else {
        parsedProfile.push({ key: key as GqlPatientDetailsFieldKey, comment: values[count].comment, value: values[count].value });
      }
    });

    await saveProfile({
      variables: { input: { id: patientProfileData?.patientById.id, details: parsedProfile } },
      ...(onSuccess && { onCompleted: onSuccess }),
    });
  };

  const resetFormData = () => {
    if (!patientProfileData?.patientById.details) {
      return;
    }
    const profileData: Partial<Record<GqlPatientDetailsFieldKey, string>> = {};

    patientProfileData?.patientById.details.forEach((profileField) => {
      profileData[profileField.key] = profileField;
    });

    setFormData(profileData);
  };

  const prettyPrintAnswer = (question: ProfileQuestion) => {
    if (!formData[question.key]?.value && !formData[question.key]?.values) {
      return null;
    }
    if (!question.options) {
      return formData[question.key]?.value || '-';
    }
    const comment = formData[question.key].comment || '';
    if (Array.isArray(formData[question.key].values)) {
      const multiSelectOptions = formData[question.key].values.map((key) => `- ${question.options[key || '']}`).join('\n') || '-';
      return `${multiSelectOptions} \n\n Comment: ${comment}`;
    }

    const answerText = question.options[formData[question.key]?.value || ''] || '-';
    return `${answerText} ${comment ? ' - ' + comment : ''}`;
  };

  const value = useMemo(
    () => ({
      formData,
      profileQuestions,
      updateField,
      updateFieldAndGoNext,
      currentQuestion,
      isFirstQuestion,
      isLastQuestion,
      allowNextOnChange,
      setCurrentQuestion,
      prettyPrintAnswer,
      resetFormData,
      canShowQuestion,
      next,
      prev,
      save,
      quizProgressPercentage: (currentQuestionId / profileQuestions.length) * 100,
      errorMessage: isErrorUpdatingProfile ? 'Error saving update' : '',
    }),
    [formData, currentQuestion]
  );

  return <PatientProfileContext.Provider value={value}>{children}</PatientProfileContext.Provider>;
};

export const useUserProfile = () => {
  const context = useContext(PatientProfileContext);

  if (!context) {
    throw new Error('This component must be used within a <PatientProfileProvider> component.');
  }

  return context;
};

PatientProfileProvider.fragments = {
  user: gql`
    fragment PatientProfileProviderPatientFragment on Patient {
      id
      isProfileComplete
      details {
        key
        value
        values
        comment
      }
    }
  `,
};

PatientProfileProvider.mutation = gql`
  mutation UpdateUserProfile($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

PatientProfileProvider.query = {
  patientById: gql`
    ${PatientProfileProvider.fragments.user}

    query PatientProfileProviderGetPatientById($input: ByIdInput) {
      patientById(input: $input) {
        ...PatientProfileProviderPatientFragment
      }
    }
  `,
};

import { BlobIcon, Button, Page } from '@when-fertility/shared/domain/common';
import React from 'react';
import { NURSE_ROUTES } from '../../nurse';

export const QuizCompletePage = () => {
  return (
    <Page variant="bright">
      <div className="pt-24 flex items-center flex-col text-center text-charcoal-100 h-auto md:h-1/2 w-full">
        {/* TODO: this icon is the wrong colour and size - allow passing classes and sizes */}
        <BlobIcon icon="check" />
        <div className="text-2xl mt-10">You&apos;re all set</div>
        <div className="mt-7">Your profile is complete and you&apos;re ready to receive your results</div>
        <div className="fixed sm:relative bottom-0 left-0 sm:bottom-auto sm:left-auto flex p-6 w-full sm:w-80 sm:mt-12">
          <Button isLink href={NURSE_ROUTES.dashboard} className="w-full">
            Go to dashboard
          </Button>
        </div>
      </div>
    </Page>
  );
};

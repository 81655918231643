import React from 'react';
import { UnauthenticatedNav } from 'domain/common/components/nav/unauthenticated-nav.component';
import { Outlet } from 'react-router-dom';

export const UnauthenticatedLayout = () => {
  return (
    <>
      <UnauthenticatedNav />
      <Outlet />
    </>
  );
};

import React from 'react';
import { format } from 'date-fns';
import { GqlUser } from '@when-fertility/shared/gql/graphql';

type Props = {
  user: Partial<GqlUser> | undefined;
  children?: React.ReactNode;
};

export const PatientInformationDetail = ({ user, children }: Props) => {
  return (
    <div className="rounded-xl border border-silver-100 mt-8">
      <React.Fragment>
        <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
          <div className="text-charcoal-60">Name</div>
          <div className="flex justify-between items-center md:ml-12">{`${user?.firstName} ${user?.lastName}`}</div>
        </div>
        <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
          <div className="text-charcoal-60">Date of birth</div>
          <div className="flex justify-between items-center md:ml-12">{user?.dateOfBirth && format(new Date(user?.dateOfBirth), 'dd/MM/yyyy')}</div>
        </div>
        <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
          <div className="text-charcoal-60">Phone number</div>
          <div className="flex justify-between items-center md:ml-12">{user?.phone}</div>
        </div>
        <div className=" md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
          <div className="text-charcoal-60">Address</div>
          <div className="flex justify-between items-center md:ml-12">{`${user?.address?.address1} ${user?.address?.address2}, ${user?.address?.city}, ${user?.address?.province_code} ${user?.address?.zip}, ${user?.address?.country}`}</div>
        </div>
        <div className="md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
          <div className="text-charcoal-60">Email</div>
          <div className="flex justify-between items-center md:ml-12">{user?.email}</div>
        </div>
      </React.Fragment>
      {children}
    </div>
  );
};
